import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Typography, Row, Col, Form, Button, Input, Modal } from 'antd';
import PageTitle from 'components/PageTitle';
import { BaseSelect } from 'components/Elements';
// request
import { getPublicPayoutsServices } from 'requests/service';
import { getPayoutsBeneficiaryAccountsOfService } from 'requests/payouts';

const CreatePayoutsForm = ({ visible, onClose, onSubmit }) => {
    const [loading, setLoading] = useState(false);
    const [services, setServices] = useState([]);
    const [selectedServiceId, setSelectedServiceId] = useState(null);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [beneficiariesBankIds, setBeneficiariesBankIds] = useState([]);
    const [beneficiariesVpaIds, setBeneficiariesVpaIds] = useState([]);
    const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState(null);
    const [modeOptions, setModeOptions] = useState([
        { label: 'NEFT', value: 'NEFT' },
        { label: 'RTGS', value: 'RTGS' },
        { label: 'IMPS', value: 'IMPS' },
        { label: 'card', value: 'card' },
    ]);

    const [formRef] = Form.useForm();

    useEffect(() => {
        const getData = async () => {
            try {
                // get services from payment module only
                const response = await getPublicPayoutsServices();
                setServices(response.records);
            } catch (err) {
                console.log(err);
            }
        }

        getData();
    }, []);


    useEffect(() => {
        formRef.setFieldValue('beneficiary_account_id', '');

        const fetchBeneficiaries = async () => {
            try {
                // get beneficiaries
                const response = await getPayoutsBeneficiaryAccountsOfService(selectedServiceId);

                const bankIds = [], vpaIds = [];
                const beneficiaries = response.records.map(record => {
                    let label = '';
                    if (record.type === 'bank_account') {
                        bankIds.push(record.id);
                        label = `${record.params.bank_account.bank_name} (${record.params.bank_account.account_number})`;
                    } else if (record.type === 'vpa') {
                        vpaIds.push(record.id);
                        label = `${record.params.vpa.address}`;
                    }

                    return { value: record.id, label: label };
                });

                setBeneficiaries(beneficiaries);
                setBeneficiariesBankIds(bankIds);
                setBeneficiariesVpaIds(vpaIds);
            } catch (err) {
                console.log(err);
            }
        }

        fetchBeneficiaries();
    }, [selectedServiceId]);

    useEffect(() => {
        formRef.setFieldValue('mode', '');

        if (beneficiariesBankIds.includes(selectedBeneficiaryId)) {
            setModeOptions([
                { label: 'NEFT', value: 'NEFT' },
                { label: 'RTGS', value: 'RTGS' },
                { label: 'IMPS', value: 'IMPS' },
                { label: 'card', value: 'card' },
            ]);
        } else if (beneficiariesVpaIds.includes(selectedBeneficiaryId)) {
            setModeOptions([
                { label: 'UPI', value: 'UPI' }
            ]);
        }
    }, [selectedBeneficiaryId])

    const onCancel = () => {
        // clear form
        formRef.resetFields();
        onClose();
    }

    const onOk = () => {
        try {
            setLoading(true);

            formRef.validateFields().then(async (formData) => {
                const { amount, ...restData } = formData;

                const payload = {
                    ...restData,
                    amount: Number(amount)
                }

                await onSubmit(payload);

                onCancel();
            });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            title="Create Payouts Transaction"
            open={visible}
            closable={false}
            onCancel={onCancel}
            onOk={onOk}
            okButtonProps={{
                loading: loading,
            }}
            okText="Submit"
        >
            <Form
                form={formRef}
                layout='vertical'
            >
                <Form.Item label="Service" name="service" rules={[{ required: true }]}>
                    <BaseSelect
                        options={services}
                        optionLabel='name'
                        optionValue='id'
                        defaultText='Choose one'
                        onChange={(value) => setSelectedServiceId(value)}
                    />
                </Form.Item>
                <Form.Item label="Beneficiary Account" name="beneficiary_account_id" rules={[{ required: true }]}>
                    <BaseSelect
                        options={beneficiaries}
                        defaultText='Choose one'
                        onChange={(value) => setSelectedBeneficiaryId(value)}
                    />
                </Form.Item>
                <Form.Item label="Mode" name="mode" rules={[{ required: true }]}>
                    <BaseSelect
                        options={modeOptions}
                        defaultText='Choose one'
                    />
                </Form.Item>
                <Form.Item label="Amount" name="amount" rules={[{ required: true }]}>
                    <Input suffix='₹' />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreatePayoutsForm;
