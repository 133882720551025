import React from 'react';
import { Typography } from 'antd';
import blackLogoIcon from 'assets/images/logo_icon.svg';

const { Title } = Typography;

const Loading = (props) => {
    return (
        <div className='loader'>
            <div className="loader-logo">
                <img src={blackLogoIcon} />
            </div>
            <Title level={4} className='mt-16'>Loading...</Title>
        </div>
    )
}

export default Loading;