import api from 'utils/api';
import { toast } from 'react-toast';

export const getAvailableUsers = (query) => {
    return new Promise((resolve, reject) => {
        return api.get(`/manager/payouts/users`, { params: query }).then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err);
        });
    })
}

export const createPayoutsAccount = (data) => {
    return new Promise((resolve, reject) => {
        return api.post(`/manager/payouts/accounts`, data).then(response => {
            resolve(response.data);
            toast.success('Create payouts account successfully');
        }).catch(err => {
            reject(err);
            toast.error(err.response.data.message);
        });
    })
}

export const getPayoutsAccounts = (query) => {
    return new Promise((resolve, reject) => {
        return api.get(`/manager/payouts/accounts`, { params: query }).then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err);
        });
    })
}

export const getPayoutsAccount = (payoutsAccountId, query) => {
    return new Promise((resolve, reject) => {
        return api.get(`/manager/payouts/accounts/${payoutsAccountId}`, { params: query }).then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err);
        });
    })
}

export const getPayoutsBeneficiaryAccounts = (payoutsAccountId, query) => {
    return new Promise((resolve, reject) => {
        return api.get(`/manager/payouts/accounts/${payoutsAccountId}/beneficiary-accounts`, { params: query }).then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err);
        });
    })
}

export const getPayoutsBeneficiaryAccountsOfService = (serviceId, query) => {
    return new Promise((resolve, reject) => {
        return api.get(`/manager/payouts/services/${serviceId}/beneficiary-accounts`, { params: query }).then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err);
        });
    })
}

export const createPayoutsBeneficiaryAccount = (payoutsAccountId, data) => {
    return new Promise((resolve, reject) => {
        return api.post(`/manager/payouts/accounts/${payoutsAccountId}/beneficiary-accounts`, data).then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err);
        });
    })
}

export const getPayouts = (query) => {
    return new Promise((resolve, reject) => {
        return api.get(`/manager/payouts`, { params: query }).then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err);
        });
    })
}

export const createPayouts = (data) => {
    return new Promise((resolve, reject) => {
        return api.post(`/manager/payouts`, data).then(response => {
            resolve(response.data);
        }).catch(err => {
            reject(err);
        });
    })
}