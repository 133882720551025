import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Typography, Row, Col, Form, Button, Input, Modal } from 'antd';
import PageTitle from 'components/PageTitle';
import { BaseSelect } from 'components/Elements';
// request
import { createPayoutsBeneficiaryAccount } from 'requests/payouts';

const CreatePayoutsBeneficiaryAccountForm = ({ visible, onClose, onSubmit, payoutsAccountId }) => {
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('bank_account');

    const typeOptions = [
        { label: 'Bank account', value: 'bank_account' },
        { label: 'VPA', value: 'vpa' },
    ];

    const [formRef] = Form.useForm();

    const onCancel = () => {
        // clear form
        formRef.resetFields();
        onClose();
    }

    const onOk = () => {
        try {
            setLoading(true);

            formRef.validateFields().then(async (formData) => {
                const { type, ...restData } = formData;

                const payload = {
                    type,
                    data: restData
                }

                await onSubmit(payload);

                onCancel();
            });
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Modal
            title="Add Beneficiary"
            open={visible}
            closable={false}
            onCancel={onCancel}
            onOk={onOk}
            okButtonProps={{
                loading: loading,
            }}
            okText="Submit"
        >
            <Form
                form={formRef}
                layout='vertical'
                initialValues={{ type }}
            >
                <Form.Item label="Type" name="type" rules={[{ required: true }]}>
                    <BaseSelect
                        options={typeOptions}
                        defaultText='Choose one'
                        onChange={(value) => setType(value)}
                    />
                </Form.Item>

                {
                    type === 'bank_account' ? (
                        <React.Fragment>
                            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="IFSC" name="ifsc" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Account number" name="account_number" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </React.Fragment>
                    ) : (
                        <Form.Item label="UPI address" name="address" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>
                    )
                }
            </Form>
        </Modal>
    );
};

export default CreatePayoutsBeneficiaryAccountForm;
