import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Spin, Table, Space, Switch } from 'antd';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import TableBar from 'components/TableBar';
import { parseQueryParams, stringifyQueryParams } from 'utils/url';
import { generateServiceName, generateSettlementStatusLabel } from 'utils/common';
// request
import { getSettlements } from 'requests/order';

const SettlementList = () => {
    const [isTableLoading, setIsTableLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(process.env.REACT_APP_RECORDS_PER_PAGE);
    const [totalCount, setTotalCount] = React.useState(0);
    const [records, setRecords] = React.useState([]);

    const config = useSelector(state => state.config);

    const searchRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    const titles = [{ path: location.pathname, title: 'Settlements' }];

    const columns = [
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
        },
        {
            title: 'Partner / User',
            render: (text, record) => (
                <div>
                    <div><b>{record.user.parent ? record.user.parent.full_name : null}</b></div>
                    <div>{record.user.full_name}</div>
                </div>
            )
        },
        {
            title: 'Settlement ID',
            key: 'settlement_id',
            dataIndex: 'settlement_id',
            render: (text, record) => (
                <div>
                    <div><strong>{generateServiceName(config.service_types, record.service_id)}</strong></div>
                    <div>{text}</div>
                </div>
            )
        },
        {
            title: 'Gross Amount',
            render: (text, record) => (
                <div>
                    <div>{record.amount_requested}</div>
                    <div>{record.platform_gross_amount - record.platform_management_fee - record.platform_reserve_amount}</div>
                    <div>&nbsp;</div>
                </div>
            )
        },
        {
            title: 'Fees',
            key: 'platform_settlement_fee',
            dataIndex: 'platform_settlement_fee',
            render: (text, record) => (
                <div>
                    <div className='text-error'>{record.fees}</div>
                    <div>{record.platform_settlement_fee}</div>
                    <div className='text-success'>{record.platform_settlement_fee > record.fees ? Number(record.platform_settlement_fee - record.fees).toFixed(2) : 0}</div>
                </div>
            )
        },
        {
            title: 'Net Amount',
            render: (text, record) => (
                <div>
                    <div>{Number(record.amount_settled) ? record.amount_settled : record.amount_pending}</div>
                    <div>{record.platform_gross_amount - record.platform_management_fee - record.platform_reserve_amount - record.platform_settlement_fee}</div>
                    <div>&nbsp;</div>
                </div>

            )
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (text) => generateSettlementStatusLabel(text)
        },
        {
            title: 'Created at',
            key: 'created_at',
            dataIndex: 'created_at',
            render: (text) => (
                <span>{new Date(text).toLocaleString('en-GB')}</span>
            )
        },
    ];

    useEffect(() => {
        const query = parseQueryParams(location);
        getRecords(query);
    }, [location]);

    const getRecords = async (query) => {
        try {
            setIsTableLoading(true);
            const response = await getSettlements(query);

            setRecords(response.records);
            setPage(response.page);
            setPerPage(response.per_page);
            setTotalCount(response.total_records);
        } catch (err) {
            console.log(err);
        } finally {
            setIsTableLoading(false);
        }
    };

    const onSearch = (keyword) => {
        let query = parseQueryParams(location);
        query = {
            ...query,
            page: 1,
            keyword: keyword,
        };

        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams(query),
        });
    };

    const onRefresh = () => {
        let query = parseQueryParams(location);
        query = {
            page: 1,
            keyword: '',
        };

        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams(query),
        });

        if (searchRef.current?.input.value) {
            searchRef.current.handleReset();
        }
    };

    const onChangeTable = (pagination) => {
        console.log(pagination);

        let query = parseQueryParams(location);
        query = {
            ...query,
            page: pagination.current,
            per_page: pagination.pageSize,
        };

        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams(query),
        });
    };

    return (
        <div>
            <PageTitle titles={titles} />
            <TableBar
                onSearch={onSearch}
                showFilter={false}
                placeholderInput="Search..."
                inputRef={searchRef}
            />
            <Spin spinning={isTableLoading}>
                <Table
                    style={{ marginTop: '10px' }}
                    dataSource={records}
                    columns={columns}
                    onChange={onChangeTable}
                    rowKey={'id'}
                    pagination={{
                        pageSize: perPage,
                        total: totalCount,
                        current: page,
                    }}
                />
            </Spin>
        </div>
    );
};

export default SettlementList;
