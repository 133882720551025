import api from 'utils/api';
import { toast } from 'react-toast';

/**
 * Order API
 */

export const createOrder = (data) => {
	return new Promise((resolve, reject) => {
		return api
			.post('/partner/orders', data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};


export const getOrders = (query) => {
	return new Promise((resolve, reject) => {
		return api
			.get('/manager/orders', { params: query })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getOrderDetail = (id) => {
	return new Promise((resolve, reject) => {
		return api
			.get(`/merchant/orders/${id}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const updateOrderDetail = (id, data) => {
	return new Promise((resolve, reject) => {
		return api.put(`/merchant/orders/${id}`, data).then((response) => {
			toast.success("Update order successfully");
			resolve(response.data);
		}).catch((err) => {
			toast.error(err.response.data.message);
			reject(err);
		});
	});
};

export const addToShipment = (data) => {
	return new Promise((resolve, reject) => {
		return api.post(`/merchant/orders/shipment`, data).then((response) => {
			toast.success("Success");
			resolve(response.data);
		}).catch((err) => {
			toast.error(err.response.data.message);
			reject(err);
		});
	});
};

/**
 * Settlements
 * @param {*} query 
 * @returns 
 */
export const getSettlements = (query) => {
	return new Promise((resolve, reject) => {
		return api
			.get('/manager/orders/settlements', { params: query })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};