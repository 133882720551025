import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Row, Col, Table } from 'antd';
// css
import 'assets/styles/overview.scss';
import OverviewPartners from './OverviewPartners';
// request

const { Title } = Typography;

const Overview = () => {

	const authUser = useSelector((state) => state.auth.authUser);


	return (
		<div className="overview-content">
			<div className="overview-header">
				<Title level={3}>Hello {authUser?.full_name},</Title>
				<Title level={2}>Welcome back 👋</Title>
			</div>
			<div className="overview-main">
				<OverviewPartners />
			</div>
		</div>
	);
};

export default Overview;
