import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
// images
import logo from 'assets/images/logo_registered.png';
import logoIcon from 'assets/images/logo_icon.svg';
// icons
import {
    PrinterIcon,
    ColorSwatchIcon
} from '@heroicons/react/outline';
import {
    Activity,
    Bag,
    TwoUsers,
    Wallet,
    Discovery,
    ArrowLeft,
    ArrowRight,
    Home,
    Category,
    Notification,
    Document,
    Scan,
    PaperPlus
} from 'react-iconly';
import UserActivationCard from 'components/UserActivationCard';

const { Sider } = Layout;

const menu = [
    {
        title: 'Overview',
        key: 'overview',
        icon: <Activity set="light" width={24} height={24} />,
        path: '/overview',
    },
    {
        title: 'Partners',
        key: 'partners',
        icon: <TwoUsers set="light" width={24} height={24} />,
        path: '/partners',
    },
    {
        title: 'Orders',
        key: 'orders',
        icon: <Bag set="light" width={24} height={24} />,
        path: '/orders',
    },
    {
        title: 'Settlements',
        key: 'settlements',
        icon: <Document set="light" width={24} height={24} />,
        path: '/settlements',
    },
    {
        title: 'Payouts',
        key: 'payouts',
        icon: <Wallet set="light" width={24} height={24} />,
        children: [
            {
                title: 'Accounts',
                key: 'payouts/accounts',
                path: '/payouts/accounts'
            },
            {
                title: 'Withdrawals',
                key: 'payouts/withdrawals',
                path: '/payouts/withdrawals'
            }
        ]
    },
    // {
    //     title: 'Sales',
    //     key: 'sales',
    //     icon: <Bag set="light" width={24} height={24} />,
    //     children: [
    //         {
    //             title: 'Orders',
    //             key: 'orders',
    //             path: '/orders',
    //         },
    //         {
    //             title: 'Abandoned Carts',
    //             key: 'abndoned-carts',
    //             path: '/abandoned-carts',
    //         },
    //     ],
    // },
    // {
    // 	title: 'Subscriptions',
    // 	key: 'subscriptions',
    // 	icon: <Chart set="light" width={24} height={24} />,
    // 	path: '/subscriptions',
    // },
    // {
    //     title: 'Services',
    //     key: 'services',
    //     icon: <Category set="light" width={24} height={24} />,
    //     path: '/services',
    // },
    // {
    //     title: 'Apps',
    //     key: 'apps',
    //     icon: <ColorSwatchIcon width={24} height={24} />,
    //     path: '/apps',
    // },
    // {
    //     title: 'Inventory',
    //     key: 'inventory',
    //     icon: <PrinterIcon width={24} height={24} />,
    //     // path: '/inventory',
    //     children: [
    //         {
    //             title: 'Categories',
    //             key: 'categories',
    //             path: '/categories',
    //         },
    //         {
    //             title: 'Products',
    //             key: 'products',
    //             path: '/products',
    //         },
    //     ],
    // },
    // {
    //     title: 'Shipments',
    //     key: 'shipments',
    //     icon: <Discovery set="light" width={24} height={24} />,
    //     // path: '/shipments',
    //     children: [
    //         {
    //             title: 'Advanced Settings',
    //             key: 'shipment/settings',
    //             path: '/shipment/settings',
    //         },
    //         {
    //             title: 'Orders',
    //             key: 'shipment/orders',
    //             path: '/shipment/orders',
    //         },
    //     ],
    // },
    // {
    //     title: 'Notifications',
    //     key: 'notifications',
    //     icon: <Notification set="light" width={24} height={24} />,
    //     // path: '/notifications',
    //     children: [
    //         {
    //             title: 'Advanced Settings',
    //             key: 'notifications/settings',
    //             path: '/notifications/settings',
    //         },
    //         {
    //             title: 'Automatic',
    //             key: 'notifications/automatic',
    //             path: '/notifications/automatic',
    //         },
    //         {
    //             title: 'Logs',
    //             key: 'notifications/logs',
    //             path: '/notifications/logs',
    //         },
    //         // {
    //         //     title: 'Manual',
    //         //     key: 'notifications/manual',
    //         //     path: '/notifications/manual',
    //         // },
    //         // {
    //         //     title: 'Bot',
    //         //     key: 'notifications/bot',
    //         //     path: '/notifications/bot',
    //         // },
    //     ],
    // },
    // {
    // 	title: 'Help',
    // 	key: 'help',
    // 	icon: <QuestionMarkCircleIcon width={24} height={24} />,
    // 	path: '/help',
    // },
];

const AppSidebar = (props) => {
    const { isCollapsed, toggleCollapse } = props;
    // const [isCollapsed, setIsCollapsed] = useState(true);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        let childIndex = -1;
        const selectedMenuItem = menu.find((item) => {
            if (item.children) {
                let tmp = item.children.findIndex((child) => location.pathname.startsWith(child.path));

                if (tmp >= 0) {
                    childIndex = tmp;
                    return true;
                }
                return false;
            } else {
                return location.pathname.startsWith(item.path);
            }
        });

        if (selectedMenuItem) {
            if (selectedMenuItem.children) {
                setSelectedKeys([selectedMenuItem.key, selectedMenuItem.children[childIndex].key]);
                if (isCollapsed) setOpenKeys([]);
                else setOpenKeys([selectedMenuItem.key]);
            } else {
                setSelectedKeys([selectedMenuItem.key]);
            }
        }
    }, [location.pathname]);

    useEffect(() => {
        if (isCollapsed) setOpenKeys([]);
    }, [isCollapsed]);

    const onToggleSubMenu = (key) => {
        if (openKeys.includes(key)) {
            setOpenKeys([]);
        } else {
            setOpenKeys([key]);
        }
    };

    return (
        <Sider className="app-sidebar" theme="light" collapsed={isCollapsed}>
            {/* {isCollapsed ? (
                <img src={logoIcon} className="logo-collapsed mb-32 ml-24 mr-24" />
            ) : (
                <img src={logo} className="logo mb-32 ml-24 mr-24" />
            )} */}
            {/* <UserActivationCard className={isCollapsed ? 'd-none' : ''} /> */}

            <Menu mode="inline" className="sidebar-menu" selectedKeys={selectedKeys} openKeys={openKeys}>
                <Menu.Item
                    key={'collapse'}
                    icon={
                        isCollapsed ? (
                            <ArrowRight set="light" width={24} height={24} />
                        ) : (
                            <ArrowLeft set="light" width={24} height={24} />
                        )
                    }
                    onClick={toggleCollapse}
                    className="menu-item--collapse"
                >
                    {isCollapsed ? 'Expand Menu' : 'Collapse Menu'}
                </Menu.Item>
                {menu.map((item) => {
                    if (item.children && item.children.length) {
                        return (
                            <Menu.SubMenu
                                key={item.key}
                                icon={item.icon}
                                title={item.title}
                                onTitleClick={() => onToggleSubMenu(item.key)}
                            >
                                {item.children.map((child) => (
                                    <Menu.Item key={child.key} icon={child.icon} onClick={() => navigate(child.path)}>
                                        {child.title}
                                    </Menu.Item>
                                ))}
                            </Menu.SubMenu>
                        );
                    }
                    return (
                        <Menu.Item key={item.key} icon={item.icon} onClick={() => navigate(item.path)}>
                            {item.title}
                        </Menu.Item>
                    );
                })}
            </Menu>
        </Sider>
    );
};

export default AppSidebar;
