import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'antd';
import OrderFulfillmentStatusDropdown from 'components/OrderFulfillmentStatusDropdown';
import OrderPaymentStatusDropdown from 'components/OrderPaymentStatusDropdown';
import { generateServiceName } from 'utils/common';

const OrderListView = ({ records, isTableLoading, pagination, selectedRecords, onChangeTable, onSelectRecords }) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const config = useSelector(state => state.config);

    const columns = [
        {
            title: 'Order ID',
            key: 'order_number',
            dataIndex: 'order_number'
        },
        {
            title: 'Partner / User',
            render: (text, record) => (
                <div>
                    <div><b>{record.user.parent ? record.user.parent.full_name : null}</b></div>
                    <div>{record.user.full_name}</div>
                </div>
            )
        },
        {
            title: 'Customer',
            key: 'email',
            dataIndex: 'email',
            width: 180,
            render: (text, record) => {
                return (
                    <div>
                        {
                            record.billing_person_name ? <div>{record.billing_person_name}</div> : null
                        }
                        {
                            text ? <div><a href={`mailto:${text}`}>{text}</a></div> : null
                        }
                        {
                            record.phone ? <div><a href={`tel:${record.phone}`}>{record.phone}</a></div> : null
                        }
                    </div>
                )
            }
        },
        {
            title: 'Amount',
            key: 'total',
            dataIndex: 'total',
            render: (text, record) => (
                <span>{record.currency} {record.total}</span>
            )
        },
        {
            title: 'Order Fees',
            key: 'management_fee',
            dataIndex: 'management_fee',
            render: (text, record) => {
                if (Number(record.payment_status) === 2) {
                    return (
                        <div>
                            <div>{record.currency} {record.management_fee}</div>
                            {
                                record.transaction ? (
                                    <div>
                                        <div className='text-error'>{record.currency} {record.transaction.fee}</div>
                                        <div className='text-success'>{record.currency} {Number(record.management_fee - record.transaction.fee).toFixed(2)}</div>
                                    </div>
                                ) : null
                            }
                        </div>
                    )
                }

                return null;
            }
        },
        {
            title: 'Status',
            key: 'payment_status',
            dataIndex: 'payment_status',
            render: (text, record) => (
                <div>
                    <OrderPaymentStatusDropdown
                        orderId={record.id}
                        defaultValue={text}
                        readonly={true}
                    />
                </div>
            )
        },
        {
            title: 'Pay method',
            key: 'payment_mode',
            dataIndex: 'payment_mode',
            render: (text, record) => {
                let vpa = '';
                if (record.params && record.params.hasOwnProperty('vpa')) {
                    vpa = record.params.vpa;
                }

                return (
                    <div>
                        <div><b>{text}</b></div>
                        <div><small>{vpa}</small></div>
                    </div>
                )
            }
        },        
        {
            title: 'Gateway / Payment ID',
            key: 'payment_tx_id',
            dataIndex: 'payment_tx_id',
            render: (text, record) => (
                <div>
                    {
                        record.transaction ? (
                            <div><strong>{generateServiceName(config.service_types, record.transaction.gateway)}</strong></div>
                        ) : null
                    }
                    <div>{text}</div>
                </div>
            )
        },
        {
            title: 'Created at',
            key: 'created_at',
            dataIndex: 'created_at',
            render: (text) => (
                <span>{new Date(text).toLocaleString('en-GB')}</span>
            )
        },
    ];

    useEffect(() => {
        console.log(selectedRecords)
        setSelectedRowKeys(selectedRecords);
    }, [selectedRecords]);

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            onSelectRecords(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({}),
    };

    return (
        <div>
            <Table
                // rowSelection={rowSelection}
                rowKey='id'
                columns={columns}
                dataSource={records}
                loading={isTableLoading}
                pagination={pagination}
                onChange={onChangeTable}
                scroll={{
                    x: true
                }}
            />
        </div>
    )
}

export default OrderListView;