import React, { useEffect, useRef, useState } from 'react';
import { Spin, Table, Button, Tag } from 'antd';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
import TableBar from 'components/TableBar';
import { parseQueryParams, stringifyQueryParams } from 'utils/url';
import CreatePayoutsForm from './CreatePayoutsForm';
// request
import { getPayouts, createPayouts } from 'requests/payouts';

const PayoutsList = () => {
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(process.env.REACT_APP_RECORDS_PER_PAGE);
    const [totalCount, setTotalCount] = useState(0);
    const [records, setRecords] = useState([]);
    const [visibleForm, setVisibleForm] = useState(false);

    const searchRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    const titles = [{ path: location.pathname, title: 'Withdrawals' }];

    const columns = [
        {
            title: 'ID',
            key: 'origin_id',
            dataIndex: 'origin_id',
        },
        {
            title: 'Partner / User',
            render: (text, record) => (
                <div>
                    {record.user.parent ?  <div><b>{record.user.parent.full_name}</b></div> : null}
                    <div>{record.user.full_name}</div>
                </div>
            )
        },
        {
            title: 'Receiver',
            render: (text, record) => {
                if (record.beneficiary_account.type === 'bank_account') {
                    return (
                        <div>
                            <div><b>{record.beneficiary_account.params.bank_account.name}</b></div>
                            <div><small>{record.beneficiary_account.params.bank_account.bank_name}</small></div>
                            <div><small>{record.beneficiary_account.params.bank_account.ifsc}</small></div>
                            <div><small>{record.beneficiary_account.params.bank_account.account_number}</small></div>
                        </div>
                    )
                } else if (record.beneficiary_account.type === 'vpa') {
                    return (
                        <div>
                            <div><b>{record.beneficiary_account.params.vpa.address}</b></div>
                        </div>
                    )
                }

                return null;
            }
        },
        {
            title: 'UTR Number',
            key: 'utr',
            dataIndex: 'utr',
        },
        {
            title: 'Gross Amount',
            key: 'amount',
            dataIndex: 'amount',
        },
        {
            title: 'Fees',
            key: 'fees',
            dataIndex: 'fees',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            render: (text) => (
                <div>{renderStatus(text)}</div>
            )
        },
        {
            title: 'Created at',
            key: 'created_at',
            dataIndex: 'created_at',
            render: (text) => (
                <span>{new Date(text).toLocaleString('en-GB')}</span>
            )
        },
    ];

    useEffect(() => {
        const query = parseQueryParams(location);
        getRecords(query);
    }, [location]);

    const getRecords = async (query) => {
        try {
            setIsTableLoading(true);
            const response = await getPayouts(query);

            setRecords(response.records);
            setPage(response.page);
            setPerPage(response.per_page);
            setTotalCount(response.total_records);
        } catch (err) {
            console.log(err);
        } finally {
            setIsTableLoading(false);
        }
    };

    const onSearch = (keyword) => {
        let query = parseQueryParams(location);
        query = {
            ...query,
            page: 1,
            keyword: keyword,
        };

        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams(query),
        });
    };

    const onRefresh = () => {
        let query = parseQueryParams(location);
        query = {
            page: 1,
            keyword: '',
        };

        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams(query),
        });

        if (searchRef.current?.input.value) {
            searchRef.current.handleReset();
        }
    };

    const onChangeTable = (pagination) => {
        console.log(pagination);

        let query = parseQueryParams(location);
        query = {
            ...query,
            page: pagination.current,
            per_page: pagination.pageSize,
        };

        navigate({
            pathname: location.pathname,
            search: stringifyQueryParams(query),
        });
    };

    const renderStatus = (text) => {
        switch (text) {
            case 'queued': return <Tag color='volcano'>{text}</Tag>;
            case 'pending': return <Tag color='gold'>{text}</Tag>;
            case 'rejected': return <Tag color='red'>{text}</Tag>;
            case 'processing': return <Tag color='blue'>{text}</Tag>;
            case 'processed': return <Tag color='green'>{text}</Tag>;
            case 'cancelled': return <Tag color='red'>{text}</Tag>;
            case 'reversed': return <Tag color='purple'>{text}</Tag>;
            default: return null;
        }
    }

    const onCreate = async (data) => {
        try {
            setIsTableLoading(true);
            await createPayouts(data);

            // refresh
            onRefresh()
        } catch (err) {
            console.log(err);
        } finally {
            setIsTableLoading(false);
        }
    }

    return (
        <div>
            <PageTitle titles={titles} />
            <TableBar
                onSearch={onSearch}
                showFilter={false}
                placeholderInput="Search..."
                inputRef={searchRef}
                children={
                    <div>
                        <Button type='primary' size='large' onClick={() => setVisibleForm(true)}>
                            Create new payouts
                        </Button>
                    </div>
                }
            />
            <Spin spinning={isTableLoading}>
                <Table
                    style={{ marginTop: '10px' }}
                    dataSource={records}
                    columns={columns}
                    onChange={onChangeTable}
                    rowKey={'id'}
                    pagination={{
                        pageSize: perPage,
                        total: totalCount,
                        current: page,
                    }}
                />
            </Spin>
            <CreatePayoutsForm
                visible={visibleForm}
                onClose={() => setVisibleForm(false)}
                onSubmit={onCreate}
            />
        </div>
    );
};

export default PayoutsList;
