import React, { useEffect, useRef, useState } from 'react';
import { Spin, Card, Descriptions, Divider, Button, Row, Table } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import PageTitle from 'components/PageTitle';
// request
import { getPayoutsAccount, getPayoutsBeneficiaryAccounts, createPayoutsBeneficiaryAccount } from 'requests/payouts';
import CreatePayoutsBeneficiaryAccountForm from './CreatePayoutsBeneficiaryAccountForm';

const PayoutsAccountDetail = () => {
    const [loading, setLoading] = useState(true);
    const [record, setRecord] = useState({});
    const [titles, setTitles] = useState([{ path: '/payouts/accounts', title: 'Payouts Accounts' }]);
    const [beneficiaryLoading, setBeneficiaryLoading] = useState(false);
    const [beneficiaryRecords, setBeneficiaryRecords] = useState([]);
    const [beneficiaryPage, setBeneficiaryPage] = useState(1);
    const [beneficiaryPerPage, setBeneficiaryPerPage] = useState(process.env.REACT_APP_RECORDS_PER_PAGE);
    const [beneficiaryTotalCount, setBeneficiaryTotalCount] = useState(0);
    const [beneficiaryVisibleModal, setBeneficiaryVisibleModal] = useState(false);

    const params = useParams();
    const location = useLocation();

    const beneficiaryColumns = [
        {
            title: 'Payouts Origin ID',
            key: 'origin_id',
            dataIndex: 'origin_id',
        },
        {
            title: 'Type',
            key: 'type',
            dataIndex: 'type',
        },
        {
            title: 'Details',
            render: (text, record) => {
                if (record.type === 'bank_account') {
                    return (
                        <div>
                            <div><b>{record.params.bank_account.name}</b></div>
                            <div><small>{record.params.bank_account.bank_name}</small></div>
                            <div><small>{record.params.bank_account.ifsc}</small></div>
                            <div><small>{record.params.bank_account.account_number}</small></div>
                        </div>
                    )
                } else if (record.type === 'vpa') {
                    return (
                        <div>
                            <div><b>{record.params.vpa.address}</b></div>
                        </div>
                    )
                }

                return null;
            }
        },
    ]

    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true);
                const response = await getPayoutsAccount(params.id);
                setRecord(response);
                setTitles([
                    { path: '/payouts/accounts', title: 'Payouts Accounts' },
                    { path: location.pathname, title: response.name }
                ]);

                setBeneficiaryLoading(true);
                const beneficiaryResponse = await getPayoutsBeneficiaryAccounts(params.id);
                setBeneficiaryRecords(beneficiaryResponse.records);
                setBeneficiaryPage(beneficiaryResponse.page);
                setBeneficiaryPerPage(beneficiaryResponse.per_page);
                setBeneficiaryTotalCount(beneficiaryResponse.total);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
                setBeneficiaryLoading(false);
            }
        }

        getData();
    }, [params]);

    const onChangeTable = async (pagination) => {
        try {
            setBeneficiaryLoading(true);
            console.log(pagination);

            const query = {
                page: pagination.current,
                per_page: pagination.pageSize,
            };

            const beneficiaryResponse = await getPayoutsBeneficiaryAccounts(params.id, query);

            setBeneficiaryRecords(beneficiaryResponse.records);
            setBeneficiaryPage(beneficiaryResponse.page);
            setBeneficiaryPerPage(beneficiaryResponse.per_page);
            setBeneficiaryTotalCount(beneficiaryResponse.total);
        } catch (err) {
            console.log(err);
        } finally {
            setBeneficiaryLoading(false);
        }
    };

    const onSubmitBeneficiary = async (data) => {
        try {
            await createPayoutsBeneficiaryAccount(params.id, data);

            // refresh
            const query = {
                page: 1,
                per_page: process.env.REACT_APP_RECORDS_PER_PAGE
            };

            const beneficiaryResponse = await getPayoutsBeneficiaryAccounts(params.id, query);

            setBeneficiaryRecords(beneficiaryResponse.records);
            setBeneficiaryPage(beneficiaryResponse.page);
            setBeneficiaryPerPage(beneficiaryResponse.per_page);
            setBeneficiaryTotalCount(beneficiaryResponse.total);
        } catch (err) {
            console.log(err);
        } finally {
            setBeneficiaryLoading(false);
        }
    }

    return (
        <div>
            <PageTitle titles={titles} />
            <Spin size='large' spinning={loading}>
                <Card>
                    <Descriptions column={3} size="small" layout="vertical">
                        <Descriptions.Item label="Name">{record.name}</Descriptions.Item>
                        <Descriptions.Item label="Email"><a href={`mailto:${record.email}`}>{record.email}</a></Descriptions.Item>
                        <Descriptions.Item label="Phone"><a href={`tel:${record.phone}`}>{record.phone}</a></Descriptions.Item>
                        <Descriptions.Item label="Payouts Origin ID">{record.origin_id}</Descriptions.Item>
                        <Descriptions.Item label="Type">{record.type}</Descriptions.Item>
                        <Descriptions.Item label="Created at">{new Date(record.created_at).toLocaleString('en-GB')}</Descriptions.Item>
                    </Descriptions>
                </Card>
            </Spin>
            <div className='mt-36'>
                <Divider orientation='left'>Beneficiary Accounts</Divider>
                <Row className="mt-16" justify='end'>
                    <Button type='primary' onClick={() => setBeneficiaryVisibleModal(true)}>Add beneficiary</Button>
                </Row>
                <Table
                    className='mt-24'
                    dataSource={beneficiaryRecords}
                    columns={beneficiaryColumns}
                    onChange={onChangeTable}
                    rowKey={'id'}
                    pagination={{
                        pageSize: beneficiaryPerPage,
                        total: beneficiaryTotalCount,
                        current: beneficiaryPage,
                    }}
                />
                <CreatePayoutsBeneficiaryAccountForm 
                    visible={beneficiaryVisibleModal}
                    onClose={() => setBeneficiaryVisibleModal(false)}
                    onSubmit={onSubmitBeneficiary}
                />
            </div>
        </div>
    );
};

export default PayoutsAccountDetail;
