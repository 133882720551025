import api from 'utils/api';

/**
 * User API
 */

export const getPartners = (query) => {
	return new Promise((resolve, reject) => {
		return api
			.get('/manager/partners', { params: query })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getPartner = (id) => {
	return new Promise((resolve, reject) => {
		return api
			.get(`/manager/partners/${id}`)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const updatePartner = (id, data) => {
	return new Promise((resolve, reject) => {
		return api
			.put(`/manager/partners/${id}`, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getUsersOfPartner = (partnerId, query) => {
	return new Promise((resolve, reject) => {
		return api
			.get(`/manager/partners/${partnerId}/users`, { params: query })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const updateUserOfPartner = (partnerId, userId, data) => {
	return new Promise((resolve, reject) => {
		return api
			.put(`/manager/partners/${partnerId}/users/${userId}`, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const onboardUsersOfPartner = (partnerId, data) => {
	return new Promise((resolve, reject) => {
		return api
			.post(`/manager/partners/${partnerId}/users/onboarding`, data)
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
