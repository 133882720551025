export const automaticAdditionalSettings = {
    send_after_15_mins: 'Send message after <span class="highlight">15 minutes</span> of abandoning the cart',
    repeat_after_24_hours: 'Repeat message after <span class="highlight">24 hours</span> if order not recovered.',
    change_fulfillment_status_to_processing:
        'Change fulfilment status to <span class="highlight">processing</span> when the customer clicks the link to verify the order',
    show_custom_message_after_order_verification: 'Show custom message after order verification',
    custom_message_after_order_verification: 'Type your custom message here',
    auto_cancel: 'Auto cancel the order if not verified within <span class="highlight">24 hours</span>',
    change_fulfillment_status_to_shipping:
        'Change fulfilment status to <span class="highlight">shipped</span> when the customer clicks the link to further process the order',
    show_custom_message_after_further_verification: 'Show custom message after further verification',
    custom_message_after_further_verification: 'Type your custom message here',
};

export const whatsappTemplateStatus = [
    'APPROVED',
    'IN_APPEAL',
    'PENDING',
    'REJECTED',
    'PENDING_DELETION',
    'DELETED',
    'DISABLED',
    'PAUSED',
    'LIMIT_EXCEEDED',
];

export const whatsappTemplateCategories = [
    'ACCOUNT_UPDATE',
    'PAYMENT_UPDATE',
    'PERSONAL_FINANCE_UPDATE',
    'SHIPPING_UPDATE',
    'RESERVATION_UPDATE',
    'ISSUE_RESOLUTION',
    'APPOINTMENT_UPDATE',
    'TRANSPORTATION_UPDATE',
    'TICKET_UPDATE',
    'ALERT_UPDATE',
    'AUTO_REPLY',
    'TRANSACTIONAL',
    'MARKETING',
    'OTP',
];

export const langMap = {
    af: 'Afrikaans',
    af_ZA: 'Afrikaans (South Africa)',
    ar: 'Arabic',
    ar_AE: 'Arabic (U.A.E.)',
    ar_BH: 'Arabic (Bahrain)',
    ar_DZ: 'Arabic (Algeria)',
    ar_EG: 'Arabic (Egypt)',
    ar_IQ: 'Arabic (Iraq)',
    ar_JO: 'Arabic (Jordan)',
    ar_KW: 'Arabic (Kuwait)',
    ar_LB: 'Arabic (Lebanon)',
    ar_LY: 'Arabic (Libya)',
    ar_MA: 'Arabic (Morocco)',
    ar_OM: 'Arabic (Oman)',
    ar_QA: 'Arabic (Qatar)',
    ar_SA: 'Arabic (Saudi Arabia)',
    ar_SY: 'Arabic (Syria)',
    ar_TN: 'Arabic (Tunisia)',
    ar_YE: 'Arabic (Yemen)',
    az: 'Azeri (Latin)',
    az_AZ: 'Azeri (Cyrillic) (Azerbaijan)',
    be: 'Belarusian',
    be_BY: 'Belarusian (Belarus)',
    bg: 'Bulgarian',
    bg_BG: 'Bulgarian (Bulgaria)',
    bs_BA: 'Bosnian (Bosnia and Herzegovina)',
    ca: 'Catalan',
    ca_ES: 'Catalan (Spain)',
    cs: 'Czech',
    cs_CZ: 'Czech (Czech Republic)',
    cy: 'Welsh',
    cy_GB: 'Welsh (United Kingdom)',
    da: 'Danish',
    da_DK: 'Danish (Denmark)',
    de: 'German',
    de_AT: 'German (Austria)',
    de_CH: 'German (Switzerland)',
    de_DE: 'German (Germany)',
    de_LI: 'German (Liechtenstein)',
    de_LU: 'German (Luxembourg)',
    dv: 'Divehi',
    dv_MV: 'Divehi (Maldives)',
    el: 'Greek',
    el_GR: 'Greek (Greece)',
    en: 'English',
    en_AU: 'English (Australia)',
    en_BZ: 'English (Belize)',
    en_CA: 'English (Canada)',
    en_CB: 'English (Caribbean)',
    en_GB: 'English (United Kingdom)',
    en_IE: 'English (Ireland)',
    en_JM: 'English (Jamaica)',
    en_NZ: 'English (New Zealand)',
    en_PH: 'English (Republic of the Philippines)',
    en_TT: 'English (Trinidad and Tobago)',
    en_US: 'English (United States)',
    en_ZA: 'English (South Africa)',
    en_ZW: 'English (Zimbabwe)',
    eo: 'Esperanto',
    es: 'Spanish',
    es_AR: 'Spanish (Argentina)',
    es_BO: 'Spanish (Bolivia)',
    es_CL: 'Spanish (Chile)',
    es_CO: 'Spanish (Colombia)',
    es_CR: 'Spanish (Costa Rica)',
    es_DO: 'Spanish (Dominican Republic)',
    es_EC: 'Spanish (Ecuador)',
    es_ES: 'Spanish (Spain)',
    es_GT: 'Spanish (Guatemala)',
    es_HN: 'Spanish (Honduras)',
    es_MX: 'Spanish (Mexico)',
    es_NI: 'Spanish (Nicaragua)',
    es_PA: 'Spanish (Panama)',
    es_PE: 'Spanish (Peru)',
    es_PR: 'Spanish (Puerto Rico)',
    es_PY: 'Spanish (Paraguay)',
    es_SV: 'Spanish (El Salvador)',
    es_UY: 'Spanish (Uruguay)',
    es_VE: 'Spanish (Venezuela)',
    et: 'Estonian',
    et_EE: 'Estonian (Estonia)',
    eu: 'Basque',
    eu_ES: 'Basque (Spain)',
    fa: 'Farsi',
    fa_IR: 'Farsi (Iran)',
    fi: 'Finnish',
    fi_FI: 'Finnish (Finland)',
    fo: 'Faroese',
    fo_FO: 'Faroese (Faroe Islands)',
    fr: 'French',
    fr_BE: 'French (Belgium)',
    fr_CA: 'French (Canada)',
    fr_CH: 'French (Switzerland)',
    fr_FR: 'French (France)',
    fr_LU: 'French (Luxembourg)',
    fr_MC: 'French (Principality of Monaco)',
    gl: 'Galician',
    gl_ES: 'Galician (Spain)',
    gu: 'Gujarati',
    gu_IN: 'Gujarati (India)',
    he: 'Hebrew',
    he_IL: 'Hebrew (Israel)',
    hi: 'Hindi',
    hi_IN: 'Hindi (India)',
    hr: 'Croatian',
    hr_BA: 'Croatian (Bosnia and Herzegovina)',
    hr_HR: 'Croatian (Croatia)',
    hu: 'Hungarian',
    hu_HU: 'Hungarian (Hungary)',
    hy: 'Armenian',
    hy_AM: 'Armenian (Armenia)',
    id: 'Indonesian',
    id_ID: 'Indonesian (Indonesia)',
    is: 'Icelandic',
    is_IS: 'Icelandic (Iceland)',
    it: 'Italian',
    it_CH: 'Italian (Switzerland)',
    it_IT: 'Italian (Italy)',
    ja: 'Japanese',
    ja_JP: 'Japanese (Japan)',
    ka: 'Georgian',
    ka_GE: 'Georgian (Georgia)',
    kk: 'Kazakh',
    kk_KZ: 'Kazakh (Kazakhstan)',
    kn: 'Kannada',
    kn_IN: 'Kannada (India)',
    ko: 'Korean',
    ko_KR: 'Korean (Korea)',
    kok: 'Konkani',
    kok_IN: 'Konkani (India)',
    ky: 'Kyrgyz',
    ky_KG: 'Kyrgyz (Kyrgyzstan)',
    lt: 'Lithuanian',
    lt_LT: 'Lithuanian (Lithuania)',
    lv: 'Latvian',
    lv_LV: 'Latvian (Latvia)',
    mi: 'Maori',
    mi_NZ: 'Maori (New Zealand)',
    mk: 'FYRO Macedonian',
    mk_MK: 'FYRO Macedonian (Former Yugoslav Republic of Macedonia)',
    mn: 'Mongolian',
    mn_MN: 'Mongolian (Mongolia)',
    mr: 'Marathi',
    mr_IN: 'Marathi (India)',
    ms: 'Malay',
    ms_BN: 'Malay (Brunei Darussalam)',
    ms_MY: 'Malay (Malaysia)',
    mt: 'Maltese',
    mt_MT: 'Maltese (Malta)',
    nb: 'Norwegian (Bokm?l)',
    nb_NO: 'Norwegian (Bokm?l) (Norway)',
    nl: 'Dutch',
    nl_BE: 'Dutch (Belgium)',
    nl_NL: 'Dutch (Netherlands)',
    nn_NO: 'Norwegian (Nynorsk) (Norway)',
    ns: 'Northern Sotho',
    ns_ZA: 'Northern Sotho (South Africa)',
    pa: 'Punjabi',
    pa_IN: 'Punjabi (India)',
    pl: 'Polish',
    pl_PL: 'Polish (Poland)',
    ps: 'Pashto',
    ps_AR: 'Pashto (Afghanistan)',
    pt: 'Portuguese',
    pt_BR: 'Portuguese (Brazil)',
    pt_PT: 'Portuguese (Portugal)',
    qu: 'Quechua',
    qu_BO: 'Quechua (Bolivia)',
    qu_EC: 'Quechua (Ecuador)',
    qu_PE: 'Quechua (Peru)',
    ro: 'Romanian',
    ro_RO: 'Romanian (Romania)',
    ru: 'Russian',
    ru_RU: 'Russian (Russia)',
    sa: 'Sanskrit',
    sa_IN: 'Sanskrit (India)',
    se: 'Sami (Northern)',
    se_FI: 'Sami (Inari) (Finland)',
    se_NO: 'Sami (Southern) (Norway)',
    se_SE: 'Sami (Southern) (Sweden)',
    sk: 'Slovak',
    sk_SK: 'Slovak (Slovakia)',
    sl: 'Slovenian',
    sl_SI: 'Slovenian (Slovenia)',
    sq: 'Albanian',
    sq_AL: 'Albanian (Albania)',
    sr_BA: 'Serbian (Cyrillic) (Bosnia and Herzegovina)',
    sr_SP: 'Serbian (Cyrillic) (Serbia and Montenegro)',
    sv: 'Swedish',
    sv_FI: 'Swedish (Finland)',
    sv_SE: 'Swedish (Sweden)',
    sw: 'Swahili',
    sw_KE: 'Swahili (Kenya)',
    syr: 'Syriac',
    syr_SY: 'Syriac (Syria)',
    ta: 'Tamil',
    ta_IN: 'Tamil (India)',
    te: 'Telugu',
    te_IN: 'Telugu (India)',
    th: 'Thai',
    th_TH: 'Thai (Thailand)',
    tl: 'Tagalog',
    tl_PH: 'Tagalog (Philippines)',
    tn: 'Tswana',
    tn_ZA: 'Tswana (South Africa)',
    tr: 'Turkish',
    tr_TR: 'Turkish (Turkey)',
    tt: 'Tatar',
    tt_RU: 'Tatar (Russia)',
    ts: 'Tsonga',
    uk: 'Ukrainian',
    uk_UA: 'Ukrainian (Ukraine)',
    ur: 'Urdu',
    ur_PK: 'Urdu (Islamic Republic of Pakistan)',
    uz: 'Uzbek (Latin)',
    uz_UZ: 'Uzbek (Cyrillic) (Uzbekistan)',
    vi: 'Vietnamese',
    vi_VN: 'Vietnamese (Viet Nam)',
    xh: 'Xhosa',
    xh_ZA: 'Xhosa (South Africa)',
    zh: 'Chinese',
    zh_CN: 'Chinese (S)',
    zh_HK: 'Chinese (Hong Kong)',
    zh_MO: 'Chinese (Macau)',
    zh_SG: 'Chinese (Singapore)',
    zh_TW: 'Chinese (T)',
    zu: 'Zulu',
    zu_ZA: 'Zulu (South Africa)',
};
