import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Row, Col, Table } from 'antd';
// css
import 'assets/styles/overview.scss';
// request
import { getPartners } from 'requests/user';


const { Title } = Typography;

const OverviewPartners = (props) => {
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(process.env.REACT_APP_RECORDS_PER_PAGE);
    const [totalCount, setTotalCount] = useState(0);
    const [records, setRecords] = useState([]);

    const columns = [
        {
            title: 'Partner Name',
            key: 'full_name',
            dataIndex: 'full_name'
        },
        {
            title: 'Total Order Amount',
            key: 'order_amount',
            dataIndex: 'order_amount',
        },
        {
            title: 'Paid Order Amount',
            key: 'paid_order_amount',
            dataIndex: 'paid_order_amount',
        },
        {
            title: 'Gateway Fees',
            key: 'gateway_fees',
            dataIndex: 'gateway_fees',
        },
        {
            title: 'Amount Settled',
            key: 'settled_amount',
            dataIndex: 'settled_amount',
        },
        {
            title: 'Platform Fees',
            key: 'paid_order_fees',
            dataIndex: 'paid_order_fees',
        },
        {
            title: 'Chargeback Amount',
            render: () => (
                <div>0</div>
            )
        },
        {
            title: 'Reserve Amount',
            key: 'paid_order_reserve_amount',
            dataIndex: 'paid_order_reserve_amount',
        },
        {
            title: 'Amount Withdrawn',
            key: 'withdrawn_amount',
            dataIndex: 'withdrawn_amount',
        },
        {
            title: 'Payout Fees',
            key: 'payouts_fees',
            dataIndex: 'payouts_fees',
        },
        {
            title: 'Net Earnings',
            render: (text, record) => (
                <div>{Number(record.paid_order_fees - record.gateway_fees).toFixed(2)}</div>
            )
        },
    ];

    useEffect(() => {
        const getData = async () => {
            try {
                setIsTableLoading(true);
                const response = await getPartners();

                setRecords(response.records);
                setPage(response.page);
                setPerPage(response.per_page);
                setTotalCount(response.total_records);
            } catch (err) {
                console.log(err);
            } finally {
                setIsTableLoading(false);
            }
        }

        getData();
    }, []);

    return (
        <Table
            loading={isTableLoading}
            dataSource={records}
            columns={columns}
            // onChange={onChangeTable}
            rowKey={'id'}
            pagination={{
                pageSize: perPage,
                total: totalCount,
                current: page,
            }}
            scroll={{
                x: true
            }}
        />
    );
}

export default OverviewPartners;
