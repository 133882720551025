import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Typography, Row, Col, Form, Button, Card } from 'antd';
import PageTitle from 'components/PageTitle';
import { BaseSelect } from 'components/Elements';
// request
import { getAvailableUsers, createPayoutsAccount } from 'requests/payouts';
import { getPublicPayoutsServices } from 'requests/service';
import { toast } from 'react-toast';


const { Title } = Typography;

const CreatePayoutsAccountForm = () => {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [services, setServices] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();

    const titles = [
        { path: '/payouts/accounts', title: 'Payouts Accounts' },
        { path: location.pathname, title: 'Create' }
    ];

    const [formRef] = Form.useForm();

    useEffect(() => {
        const getData = async () => {
            try {
                // get users
                const response = await getAvailableUsers();
                setUsers(response.users);

                // get services from payment module only
                const response1 = await getPublicPayoutsServices();
                setServices(response1.records);
            } catch (err) {
                console.log(err);
            }
        }

        getData();
    }, []);

    const onSubmit = async (formData) => {
        try {
            setLoading(true);

            const response = await createPayoutsAccount(formData);

            navigate('/payouts/accounts');
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div>
            <PageTitle titles={titles} />
            <Row>
                <Col xs={24} sm={24} md={18} lg={12}>
                    <Card>
                        <Form
                            form={formRef}
                            layout='vertical'
                            onFinish={onSubmit}
                        >
                            <Form.Item label="User" name="user_id" rules={[{ required: true }]}>
                                <BaseSelect
                                    options={users}
                                    optionLabel='full_name'
                                    optionValue='id'
                                    additionalLabel='email'
                                    defaultText='Choose one'
                                />
                            </Form.Item>
                            <Form.Item label="Service" name="service_id" rules={[{ required: true }]}>
                                <BaseSelect
                                    options={services}
                                    optionLabel='name'
                                    optionValue='id'
                                    defaultText='Choose one'
                                />
                            </Form.Item>
                            <Button type='primary' htmlType='submit' loading={loading}>
                                Create Payouts Account
                            </Button>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CreatePayoutsAccountForm;
